const csrfToken = document.querySelector('meta[name=csrf-token]')?.content

$('.declineButton').on('click', function (event) {
  const $btn = $(event.target)
  const invite = $btn.attr('data-invite')
  $.ajax({
    url: '/company/invite_decline',
    method: 'POST',
    data: { invite_id: invite, mode: 'W' },
    headers: {
      'x-csrf-token': csrfToken,
    },
  })
    .done(() => {
      $btn.closest('tr').hide()
    })
    .fail(() => {
      alert('There was an error declining this invitation, you can try again in your dashboard.')
    })
    .always(() => {
      if (moreToDo()) return

      goToDashboard()
    })
})

function moreToDo() {
  let visible = -1 // the head has a tr that is always visible
  const tr = $('#inviteTable').find('tr')
  tr.each(function (e) {
    if ($(tr[e]).is(':hidden') == false) {
      visible += 1
    }
  })

  return visible > 0
}

function goToDashboard() {
  top.location.href = '/app_dashboard/index'
}
